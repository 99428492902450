<div class="error-container">
  <div class="fs-4 error-container__icon-container d-inline-flex">
    <span class="mt-1"><mhicon-x-circle size="xxl"></mhicon-x-circle></span>
    <h1 class="d-flex align-items-center ms-4">
      {{ "GENERIC.ERROR.HEADING" | translate }}
    </h1>
  </div>
  <div class="fw-bold mb-4 h2">
    {{ errorMessageKey | translate }}
  </div>
  @if (
    genericError && (genericError.status !== 0 || genericError.message !== "")
  ) {
    <div class="mb-2">
      <button
        type="button"
        class="btn btn-outline-primary pe-3"
        (click)="detailedError.toggle()"
        [attr.aria-expanded]="!isCollapse">
        @if (!isCollapse) {
          <span>
            <mhicon-chevron-down size="md"></mhicon-chevron-down>
          </span>
        }
        @if (isCollapse) {
          <span>
            <mhicon-chevron-right size="md"></mhicon-chevron-right>
          </span>
        }
        {{ "GENERIC.ERROR.SHOW_ERROR" | translate }}
      </button>
      <div
        #detailedError="ngbCollapse"
        [(ngbCollapse)]="isCollapse"
        (shown)="renderDetailedError()">
        @if (errorSupportMessage) {
          <div class="card rounded-0">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div>
                  @if (genericError.status !== 0) {
                    <div>
                      <span class="fw-bold">
                        {{ "GENERIC.ERROR.ERROR_CODE" | translate }}
                      </span>
                      {{ genericError.status }}
                    </div>
                  }
                  @if (genericError.message !== "") {
                    <div>
                      <span class="fw-bold">
                        {{ "GENERIC.ERROR.ERROR_MESSAGE" | translate }}
                      </span>
                      <pre><code [innerHTML]="genericError.message | json"></code></pre>
                    </div>
                  }
                  @if (
                    genericError.status !== 0 || genericError.message !== ""
                  ) {
                    <div [innerHTML]="errorSupportMessage"></div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
  <div class="mb-2">
    <button type="button" (click)="onExitClick()" class="btn btn-primary mt-2">
      {{ "GENERIC.ERROR.EXIT_BUTTON" | translate }}
    </button>
  </div>
</div>
