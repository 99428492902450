import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TECH_SUPPORT_CENTER_URL } from 'up';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UPUtilityService } from 'up';
@Component({
  selector: 'go-relink-launch',
  templateUrl: './relink-launch.component.html',
  styleUrls: ['./relink-launch.component.scss'],
})
export class RelinkLaunchComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private upUtilityService: UPUtilityService
  ) {}
  techSupportUrl: string = TECH_SUPPORT_CENTER_URL + 'ContactUs';
  hasRelinkError: boolean = false;
  ngOnInit(): void {
    this.startRelink();
  }

  startRelink() {
    this.upUtilityService.postMessageToLMS({
      subject: 'app_ready',
    });
    window.addEventListener('message', (event: MessageEvent) => {
      if (
        event.data.subject === 'com.mheducation.openlearning.lti.relinkDisabled'
      ) {
        window.removeEventListener('message', () => {});
        this.selectProduct();
      } else if (
        event.data.subject === 'com.mheducation.openlearning.lti.relinkComplete'
      ) {
        window.removeEventListener('message', () => {});
        if (!event.data.success) {
          this.hasRelinkError = true;
        } else {
          let hasGOLaunchedNewTab = this.upUtilityService.launchAppInNewTab(
            'GO',
            false
          );
          if (hasGOLaunchedNewTab) {
            hasGOLaunchedNewTab.location.href =
              location?.origin + '/products/chapters';
            this.router.navigate(['/products/warning'], {
              state: {
                platform: 'McGraw Hill GO',
                isCourseLaunchLevelLaunch:
                  this.sharedService.getCourseLaunchLevel(),
              },
            });
          } else {
            this.sharedService.getLtiParams().subscribe(() => {
              this.router.navigateByUrl('/products/launch');
            });
          }
        }
      }
    });
  }

  selectProduct() {
    this.sharedService.setIsCourseCopy(false);
    this.router.navigateByUrl('/products/select');
  }
}
