@if (!hasRelinkError) {
  <div>
    <p class="course-copy-loader-text text-center">
      {{ "INSTRUCTOR.COURSE_COPY.COURSE_COPY_TEXT" | translate }}
    </p>
  </div>
} @else {
  <p class="course-copy-loader-text text-center">
    {{ "INSTRUCTOR.COURSE_COPY.COURSE_COPY_ERROR_TEXT" | translate }}
    <a href="{{ techSupportUrl }}" class="link" target="_blank">
      <span class="link">
        {{ "INSTRUCTOR.COURSE_COPY.COURSE_COPY_SUPPORT_LINK" | translate }}
      </span>
    </a>
  </p>
}
